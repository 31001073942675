import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";

import SlideTableRow from "../atoms/SlideTableRow";

import { HEADERS } from "../constants";

function TableWithPagination({ data, selectedSlides, setSelectedSlides }) {
  const { results: rows } = data;

  return (
    <Container sx={{ padding: "24px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {HEADERS.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <SlideTableRow
              row={row}
              selectedSlides={selectedSlides}
              setSelectedSlides={setSelectedSlides}
            />
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default TableWithPagination;
