import { useState, useEffect, forwardRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";

import { ICD10_URL } from "shared/api/urls";
import useApiGetRequestHook from "shared/api/getRequestHook";
import TableWithPagination from "./TableWithPagination";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Icd10Modal({ icd10, setIcd10 }) {
  const [open, setOpen] = useState(false);

  const [url, setUrl] = useState(ICD10_URL);
  const [requestParams, setRequestParams] = useState({
    page: 1,
    search: null,
  });
  const [data, setData] = useState({});

  useEffect(() => {
    const { search } = requestParams;
    let newUrl = `${ICD10_URL}?page=${requestParams.page}`;

    if (search && search.length !== 0) {
      newUrl = newUrl + `&search=${search}`;
    }

    setUrl(newUrl);
  }, [requestParams]);

  const { isCompleted, isSuccessful, isFailed, response, error } =
    useApiGetRequestHook(url);

  useEffect(() => {
    if (isSuccessful) setData(response.data);
  }, [isCompleted, isSuccessful, response]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, value) => {
    setRequestParams({ ...requestParams, page: value });
  };

  const setSearch = (e) => {
    const { value } = e.target;
    setRequestParams({ ...requestParams, page: 1, search: value });
  };

  const dataLength = Object.keys(data).length;

  return (
    <>
      {isSuccessful && dataLength > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", padding: 1 }}>
          <Typography
            component="h3"
            variant="h6"
            draggable={false}
            sx={{ mr: 1 }}
          >
            Код МКБ-10:
          </Typography>
          {icd10 && <Box sx={{ p: 1 }}>{icd10.code}</Box>}
          <Button variant="outlined" onClick={handleClickOpen}>
            Открыть окно выбора кода МКБ-10
          </Button>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Toolbar>
                <Typography
                  sx={{ mr: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Код МКБ-10
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                id="search"
                label="Введите код или диагноз для поиска"
                variant="standard"
                sx={{ width: "80%", margin: 1 }}
                onChange={(e) => setSearch(e)}
              />
              <TableWithPagination
                data={data}
                requestParams={requestParams}
                setRequestParams={setRequestParams}
                setOpen={setOpen}
                setIcd10={setIcd10}
              />
              <Pagination
                count={data.total_pages}
                onChange={handleChange}
                sx={{ mb: 3 }}
              />
            </Box>
          </Dialog>
        </Box>
      )}
      {isFailed && <h3>Ошибка загрузки</h3>}
    </>
  );
}

export default Icd10Modal;
