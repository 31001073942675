import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "shared/model/auth/reducer";
import { LOGOUT_URL } from "shared/api/urls";
import postApiRequest from "shared/api/postApiRequest";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { logout } from "shared/model/auth/reducer";

function TopBar({ isMenuHidden, setMenuHidden }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useSelector(getAuth);

  const handleLogout = async () => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };
    const data = { refresh: refreshToken };
    const response = await postApiRequest(LOGOUT_URL, data, config);
    if (response.status !== 205) {
      console.log("ERROR");
    }
    dispatch(logout());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuClickHandler = () => {
    setMenuHidden(!isMenuHidden);
  };

  return (
    <AppBar position="static">
      <Toolbar
        variant="dense"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <MenuOpenIcon className="button" onClick={menuClickHandler} />
        </IconButton>
        <Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Выход</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
