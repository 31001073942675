const ROUTES = [
  {
    path: "/",
    text: "Обработанные слайды",
  },
  {
    path: "/upload/",
    text: "Добавить слайд в обработку",
  },
  {
    path: "/organizations/",
    text: "Справочник организаций",
  },
  {
    path: "/export/",
    text: "Экспорт",
  },
];

export default ROUTES;
