import { Link } from "react-router-dom";

import { Box } from "@mui/material";

function Item({ item: { path, text } }) {
  return (
    <Box
      sx={{
        backgroundColor: "#162638",
        padding: 3,
        textAlign: "center",
        border: "solid 1px white",
      }}
    >
      <Link to={path}>{text}</Link>
    </Box>
  );
}

export default Item;
