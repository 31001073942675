const LOGIN_URL = "/api/token/";
const LOGOUT_URL = "/api/logout/";
const SLIDE_URL = "/api/slide/";
const SLIDES_URL = "/api/slides/";
const ICD10_URL = "/api/icd-10/";
const ORGANIZATION_URL = "/api/organization/";
const ANNOTATION_URL = "/api/annotation/";
const EXPORT_URL = "/api/export-slide/";

export {
  LOGIN_URL,
  LOGOUT_URL,
  SLIDE_URL,
  SLIDES_URL,
  ICD10_URL,
  ORGANIZATION_URL,
  ANNOTATION_URL,
  EXPORT_URL,
};
