import React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

const SlideTableRow = ({
  row: item,
  index,
  selectedSlides,
  setSelectedSlides,
}) => {
  const checked =
    selectedSlides.filter((slide) => slide.id === item.id).length !== 0;

  const handleChange = (event, item) => {
    const {
      target: { checked },
    } = event;
    const index = selectedSlides.indexOf(item);

    if (checked) {
      setSelectedSlides([
        ...selectedSlides.slice(0, index),
        item,
        ...selectedSlides.slice(index),
      ]);
    } else {
      setSelectedSlides([
        ...selectedSlides.slice(0, index),
        ...selectedSlides.slice(index + 1),
      ]);
    }
  };

  return (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        {item.name}
      </TableCell>
      <TableCell>{item.icd_10}</TableCell>
      <TableCell>{item.processing_date}</TableCell>
      <TableCell>
        <Checkbox checked={checked} onChange={(e) => handleChange(e, item)} />
      </TableCell>
    </TableRow>
  );
};

export default SlideTableRow;
