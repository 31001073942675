import React from "react";
import IconButton from "@mui/material/IconButton";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";

const AnnotationsToolPicker = ({
  annotationsDrawingConfig,
  setAnnotationsDrawingConfig,
}) => {
  const { enabled, tool } = annotationsDrawingConfig;

  const clickHandler = (toolName) => {
    if (tool === toolName) {
      setAnnotationsDrawingConfig({
        ...annotationsDrawingConfig,
        enabled: !enabled,
      });
      return;
    }
    setAnnotationsDrawingConfig({
      ...annotationsDrawingConfig,
      enabled: enabled ? enabled : !enabled,
      tool: toolName,
    });
  };

  return (
    <div>
      <IconButton
        size="large"
        color="primary"
        aria-label="upload picture"
        component="label"
        style={{
          backgroundColor:
            enabled && tool === "rect" ? "rgba(0,0,0,0.3)" : null,
          color: enabled && tool === "rect" ? "white" : null,
        }}
        onClick={() => clickHandler("rect")}
      >
        <RectangleOutlinedIcon />
      </IconButton>
      <IconButton
        size="large"
        color="primary"
        aria-label="upload picture"
        component="label"
        style={{
          backgroundColor:
            enabled && tool === "polygon" ? "rgba(0,0,0,0.3)" : null,
          color: enabled && tool === "polygon" ? "white" : null,
        }}
        onClick={() => clickHandler("polygon")}
      >
        <PolylineOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default AnnotationsToolPicker;
