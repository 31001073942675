import { baseApiInstance } from "./baseApiInstance";

const deleteApiRequest = async (url, data = {}, config = {}) => {
  const response = await baseApiInstance
    .delete(url, data, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export default deleteApiRequest;
