import { baseApiInstanceWithoutToken } from "./baseApiInstance";

const postApiRequestWithoutToken = async (url, data, config = {}) => {
  const response = await baseApiInstanceWithoutToken
    .post(url, data, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export default postApiRequestWithoutToken;
