import React from "react";
import Button from "@mui/material/Button";

const UploadButton = ({ uploadHandler, isUploadButtonDisabled, className }) => (
  <Button
    color="primary"
    variant="contained"
    size="large"
    disabled={isUploadButtonDisabled}
    className={className}
    onClick={uploadHandler}
  >
    Загрузить
  </Button>
);

export { UploadButton };
