import CanvasOverlayHd from "shared/lib/canvas-overlay-hd";

import { drawGeoJson } from "./geoJsonDrawer";

const initMarks = (viewer, tisuues) => {
  let marks = [];

  tisuues.forEach((tisuue) => {
    const mark = new CanvasOverlayHd(viewer, {
      onRedraw: function (opts) {
        const { context: ctx } = opts;
        drawGeoJson(ctx, tisuue.geojson);
      },
    });

    // render overlay after filtering marks
    mark.resize();
    mark.updateCanvas();

    marks.push(mark);
  });

  return marks;
};

export { initMarks };
