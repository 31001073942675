import Stack from "@mui/material/Stack";
import ROUTES from "shared/routes/constants";
import Item from "../atoms/Item";

const Items = () => (
  <Stack className="menu" spacing={2}>
    {ROUTES.map((item, index) => (
      <Item key={index} item={item} />
    ))}
  </Stack>
);

export default Items;
