import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "shared/model/auth/reducer";
import { LOGIN_URL } from "shared/api/urls";
import postApiRequestWithoutToken from "shared/api/postApiRequestWithoutToken";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import background from "assets/img/background.jpg";

const Login = ({ isAuthenticated }) => {
  const [state, setState] = useState({});
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleChange = (e) => {
    if (isError) {
      setIsError(false);
    }
    setState({ ...state, [e.currentTarget.id]: e.currentTarget.value });
  };

  const authenticate = async (e) => {
    e.preventDefault();
    const response = await postApiRequestWithoutToken(LOGIN_URL, {
      username: state.login,
      password: state.password,
    });
    if (response.status === 200) {
      const { data } = response;
      dispatch(login(data));
    } else {
      setIsError(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
        backgroundImage: `url(${background})`,
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "300px",
          position: "absolute",
          top: "calc(50% - 132px)",
          left: "calc(50% - 182px)",
          background: "white",
          borderRadius: 2,
          padding: 2,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="login"
          label="Логин"
          variant="outlined"
          onChange={handleChange}
          sx={{ margin: 1 }}
          error={isError ? true : false}
          helperText={isError ? "Неверный логин или пароль" : null}
        />
        <TextField
          id="password"
          label="Пароль"
          type="password"
          variant="outlined"
          onChange={handleChange}
          sx={{ margin: 1 }}
          error={isError ? true : false}
          helperText={isError ? "Неверный логин или пароль" : null}
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          size="large"
          onClick={(e) => authenticate(e)}
          sx={{ margin: 1 }}
        >
          Войти
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
