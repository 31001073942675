import { useState, useEffect } from "react";

import { baseApiInstanceWithRefresh } from "./baseApiInstance";

const useApiGetRequestHook = (url) => {
  const [result, setResult] = useState({
    isCompleted: false,
    isSuccessful: false,
    isFailed: false,
    response: null,
    error: null,
  });

  useEffect(() => {
    baseApiInstanceWithRefresh
      .get(url)
      .then((res) =>
        setResult({
          ...result,
          isCompleted: true,
          isSuccessful: true,
          response: res,
        })
      )
      .catch((error) =>
        setResult({
          ...result,
          isCompleted: true,
          isFailed: true,
          error: error,
        })
      );
    //eslint-disable-next-line
  }, [url]);

  return result;
};

export default useApiGetRequestHook;
