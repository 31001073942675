import { store } from "shared/model/store";
import { refresh, logout } from "shared/model/auth/reducer";
import { baseApiInstanceWithoutToken } from "shared/api/baseApiInstance";

const refreshAccessToken = () => {
  const refreshToken = JSON.parse(localStorage.getItem("app")).auth
    .refreshToken;
  console.log(refreshToken);
  baseApiInstanceWithoutToken
    .post("api/token/refresh/", { refresh: refreshToken })
    .then((response) => {
      const data = response.data;
      console.log(data);
      store.dispatch(refresh(data));
    })
    .catch((err) => store.dispatch(logout()));
};

export { refreshAccessToken };
