import React from "react";

import Box from "@mui/material/Box";

import ExportSlidesModal from "./molecules/ExportSlidesModal";
import PageWrapper from "components/PageWrapper";

function Export() {
  return (
    <PageWrapper>
      <Box sx={{ width: "70%", margin: "0 auto" }}>
        <ExportSlidesModal />
      </Box>
    </PageWrapper>
  );
}

export default Export;
