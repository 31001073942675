import React, { useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const getFormParams = (type, item, displayedItems) => {
  let params = {};

  if (type === "tissues") {
    params.label = `${item.name}`;
    params.checked =
      displayedItems.filter((displayedItem) => displayedItem.id === item.id)
        .length !== 0;
  } else {
    params.label = `${item.employee.first_name}`;
    params.checked =
      displayedItems.filter((displayedItem) => displayedItem.uuid === item.uuid)
        .length !== 0;
  }
  params.clr = item.geojson[0].properties.fillColor;
  return params;
};

export default function CheckboxWithChilds({
  type,
  label,
  baseItems,
  displayedItems,
  setItems,
}) {
  const [checked, setChecked] = useState([true, false]);

  const handleChange1 = (event) => {
    const {
      target: { checked },
    } = event;

    if (checked) {
      setItems(baseItems);
    } else {
      setItems([]);
    }
    setChecked([event.target.checked, event.target.checked]);
  };

  const updateItem = (event, item) => {
    const {
      target: { checked },
    } = event;
    const index = displayedItems.indexOf(item);

    if (checked) {
      setItems([
        ...displayedItems.slice(0, index),
        item,
        ...displayedItems.slice(index),
      ]);
    } else {
      setItems([
        ...displayedItems.slice(0, index),
        ...displayedItems.slice(index + 1),
      ]);
    }
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {baseItems.map((item, index) => {
        const { label, checked, clr } = getFormParams(type, item, displayedItems);
        return (
          <FormControlLabel
            key={index}
            label={label}
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => updateItem(e, item)}
                style = {{color: clr}}
              />
            }
          />
        );
      })}
    </Box>
  );

  return (
    <div>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
          />
        }
      />
      {children}
    </div>
  );
}
