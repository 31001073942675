/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import OpenSeaDragon from "openseadragon";
import "@recogito/annotorious-openseadragon/dist/annotorious.min.css";

import Box from "@mui/material/Box";
import AnnotationsToolPicker from "./atoms/AnnotationsToolPicker";
import OverlaysHandler from "./molecules/OverlaysHandler";

import { initMarks } from "./lib/marks";
import { initAnnotations } from "./lib/annotationsHandler";

const OpenSeaDragonViewer = ({
  data: {
    id: slideId,
    dzi: { file },
    tisuues,
    annotations,
  },
}) => {
  const [viewer, setViewer] = useState(null);
  const [annotationsOverlay, setAnnotationsOverlay] = useState(null);
  const [marksOverlay, setMarksOverlay] = useState(null);

  const [displayedTisuues, setDisplayedTisuues] = useState(null);
  const [displayedAnnotations, setDisplayedAnnotations] = useState(null);

  const [annotationsDrawingConfig, setAnnotationsDrawingConfig] = useState({
    enabled: false,
    tool: "polygon",
  });

  const InitOpenseadragon = () => {
    viewer && viewer.destroy();
    const initViewer = OpenSeaDragon({
      id: "openSeaDragon",
      showNavigator: true,
      prefixUrl: "/openseadragon/images/",
      tileSources: file,
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 2,
      minZoomLevel: 1,
      visibilityRatio: 1,
      zoomPerScroll: 2,
    });

    setViewer(initViewer);
  };

  useEffect(() => {
    if (file && viewer) {
      viewer.open(file.source);
    }
  }, [file]);

  useEffect(() => {
    InitOpenseadragon();
    return () => {
      viewer && viewer.destroy();
    };
  }, []);

  useEffect(() => {
    setDisplayedTisuues(tisuues);
  }, [tisuues]);

  useEffect(() => {
    setDisplayedAnnotations(annotations);
  }, [annotations]);

  useEffect(() => {
    if (viewer && displayedTisuues)
      setMarksOverlay(initMarks(viewer, displayedTisuues));
    if (viewer && displayedAnnotations)
      setAnnotationsOverlay(
        initAnnotations(viewer, displayedAnnotations, slideId)
      );

    return () => {
      marksOverlay &&
        marksOverlay.forEach((markOverlay) => markOverlay.clear());
      annotationsOverlay &&
        annotationsOverlay.forEach((annotationOverlay) =>
          annotationOverlay.destroy()
        );
    };
  }, [viewer]);

  useEffect(() => {
    if (viewer && marksOverlay) {
      marksOverlay.forEach((markOverlay) => {
        markOverlay.clear();
      });
      setMarksOverlay(initMarks(viewer, displayedTisuues));
    }
  }, [displayedTisuues]);

  useEffect(() => {
    if (viewer && annotationsOverlay) {
      annotationsOverlay.forEach((annotationOverlay) => {
        annotationOverlay.destroy();
      });
      setAnnotationsOverlay(
        initAnnotations(viewer, displayedAnnotations, slideId)
      );
    }
  }, [displayedAnnotations]);

  useEffect(() => {
    if (annotationsOverlay) {
      const ownerAnnotations = annotationsOverlay[1];
      const { enabled, tool } = annotationsDrawingConfig;
      ownerAnnotations.setDrawingEnabled(enabled);
      ownerAnnotations.setDrawingTool(tool);
    }
  }, [annotationsDrawingConfig]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "8px 0",
        }}
      >
        <AnnotationsToolPicker
          annotationsDrawingConfig={annotationsDrawingConfig}
          setAnnotationsDrawingConfig={setAnnotationsDrawingConfig}
        />
        <OverlaysHandler
          tisuues={tisuues}
          annotations={annotations}
          displayedTisuues={displayedTisuues}
          displayedAnnotations={displayedAnnotations}
          setDisplayedTisuues={setDisplayedTisuues}
          setDisplayedAnnotations={setDisplayedAnnotations}
        />
      </Box>
      <div
        id="openSeaDragon"
        style={{
          height: "80vh",
          width: "100%",
        }}
      />
    </>
  );
};

export default OpenSeaDragonViewer;
