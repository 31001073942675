import React from "react";
import Button from "@mui/material/Button";

const ChooseButton = ({ status, UPLOADING_STATUS }) => {
  return (
    <Button
      color="primary"
      variant="outlined"
      size="large"
      component="span"
      disabled={status === UPLOADING_STATUS.Loading}
    >
      Выбрать файлы
    </Button>
  );
};

export { ChooseButton };
