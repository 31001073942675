import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

function OrganizationSelect({ organization, organizations, setOrganization }) {
  const handleOrganizationChange = (event) => {
    setOrganization(event.target.value);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", padding: 1 }}>
      <Typography component="h3" variant="h6" draggable={false} sx={{ mr: 1 }}>
        Огранизация:
      </Typography>
      <Select
        labelId="organization-label"
        id="organization"
        value={organization}
        onChange={handleOrganizationChange}
        sx={{ height: 36, minWidth: 200 }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {organizations.map((organization) => (
          <MenuItem key={organization.id} value={organization.code}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default OrganizationSelect;
