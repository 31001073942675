import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import OpenSeaDragonViewer from "components/OpenSeaDragonViewer";

import { baseApiInstanceWithRefresh } from "shared/api/baseApiInstance";
import PageWrapper from "components/PageWrapper";

const processingResult = (data, state, setState) => {
  if (data.dzi) {
    setState({ ...state, isCompleted: true, data: data });
    return;
  }

  setState({ ...state, isCompleted: true, isFailed: true });
};

function ImageView() {
  const { slideId } = useParams();

  const [state, setState] = useState({
    isCompleted: false,
    isFailed: false,
    data: null,
  });

  useEffect(() => {
    const url = `/api/slide/${slideId}`;

    baseApiInstanceWithRefresh
      .get(url)
      .then((res) => {
        processingResult(res.data, state, setState);
      })
      .catch((error) => {
        console.log(error);
        setState({ isCompleted: true, isFailed: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideId]);

  const { isCompleted, isFailed, data } = state;

  return (
    <PageWrapper>
      {isCompleted && !isFailed ? (
        <>
          <OpenSeaDragonViewer data={data} />
        </>
      ) : (
        <h3>Этого слайда не существует</h3>
      )}
    </PageWrapper>
  );
}

export default ImageView;
