import { useState, useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";

import ProgressBar from "components/ProgressBar";

import useApiPostRequestWithProgressHook from "shared/api/postRequestWithProgressHook";

import RemoveItemDialog from "./RemoveItemDialog";
import { removeFile } from "../lib/fileHandlers";
import { UPLOADING_STATUS } from "../constants";

const FileListItem = ({
  url,
  file,
  index,
  files,
  status,
  icd10,
  organization,
  setFiles,
  setStatus,
  uploadState,
  setUploadState,
  uploadQueue,
  setUploadQueue,
}) => {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [requestData, setRequestData] = useState(null);

  const [fileIsLoading, setFileIsLoading] = useState(false);

  const updateUploadProgress = (fileIndex, percentCompleted) => {
    setUploadState((prevState) => {
      const newState = [...prevState];
      const currentUploadState = {
        ...prevState[index],
        progress: percentCompleted,
      };
      newState[fileIndex] = currentUploadState;
      return newState;
    });
  };

  const { isCompleted, isSuccessful, isFailed, response, error } =
    useApiPostRequestWithProgressHook(
      url,
      requestData,
      index,
      updateUploadProgress
    );

  useEffect(() => {
    setUploadState((prevState) => {
      const newState = prevState.map((elem) => {
        if (elem.id === index) {
          return {
            ...elem,
            status: isCompleted,
          };
        }
        return elem;
      });
      return newState;
    });

    if (isCompleted) {
      setUploadQueue((prevState) => {
        const { loading, completed } = prevState;
        const currentFile = { id: index };

        const newLoading = loading.filter((elem) => elem.id !== index);

        const newCompleted = [...completed];
        newCompleted.push(currentFile);

        return {
          ...prevState,
          loading: newLoading,
          completed: newCompleted,
        };
      });
    }
  }, [isCompleted, isSuccessful, isFailed]);

  useEffect(() => {
    if (error) {
      setUploadState((prevState) => {
        const newState = [...prevState];
        const currentUploadState = {
          ...prevState[index],
          error:
            "data" in error.response
              ? error.response.data
              : error.code === "ERR_NETWORK"
              ? ["Ошибка подключения к серверу"]
              : "",
        };
        newState[index] = currentUploadState;
        return newState;
      });
    }
  }, [error]);

  const startLoading = () => {
    let data = new FormData();
    data.append("svs", file, file.name);
    data.append("icd_10.code", icd10.code);
    data.append("organization.code", organization);
    data.append("name", file.name);

    setRequestData(data);
  };

  useEffect(() => {
    if (status === UPLOADING_STATUS.Loading) {
      const { waiting } = uploadQueue;
      const currentWaitingFile = waiting.filter((file) => file.id === index);
      if (currentWaitingFile.length === 0 && !fileIsLoading) {
        setFileIsLoading(true);
        startLoading();
      }
    }
  }, [uploadQueue, status]);

  return (
    <>
      <ListItem key={index} divider>
        <span>{file.name}</span>
        {status === UPLOADING_STATUS.Loading && (
          <ProgressBar percentage={uploadState[index].progress} />
        )}
        {isCompleted && uploadState[index].error && (
          <span>{JSON.stringify(uploadState[index].error)}</span>
        )}
        {isCompleted && isSuccessful && !isFailed && (
          <span>Загрузка успешно завершена!</span>
        )}
        {status === UPLOADING_STATUS.Initialization && (
          <CloseIcon onClick={() => setRemoveDialogOpen(true)} />
        )}
      </ListItem>
      <RemoveItemDialog
        index={index}
        fileName={file.name}
        removeFile={() => removeFile(index, files, setFiles, setStatus)}
        removeDialogOpen={removeDialogOpen}
        setRemoveDialogOpen={setRemoveDialogOpen}
      />
    </>
  );
};

export { FileListItem };
