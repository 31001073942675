import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";

import { NavLink } from "react-router-dom";

import { HEADERS } from "../constants";

function TableWithPagination({ data, setPage }) {
  const { results: rows, total_pages } = data;

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container sx={{ padding: "24px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {HEADERS.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.organization}
              </TableCell>
              <TableCell>{row.icd_10}</TableCell>
              <TableCell>
                {row.dzi ? (
                  <NavLink to={`/view/${row.id}/`}>click</NavLink>
                ) : null}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.processing_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        sx={{ margin: "12" }}
        count={total_pages}
        onChange={handleChange}
      />
    </Container>
  );
}

export default TableWithPagination;
