import { useState, useEffect } from "react";

import PageWrapper from "components/PageWrapper";
import TableWithPagination from "./molecules/TableWithPagination";
import useApiGetRequestHook from "shared/api/getRequestHook";
import { SLIDES_URL } from "shared/api/urls";

import "assets/css/home.css";

export function Home() {
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState(`${SLIDES_URL}?page=${page}`);
  const [data, setData] = useState({});

  useEffect(() => {
    setUrl(`${SLIDES_URL}?page=${page}`);
  }, [page]);

  const { isCompleted, isSuccessful, isFailed, response, error } =
    useApiGetRequestHook(url);

  useEffect(() => {
    if (isSuccessful) setData(response.data);
  }, [isCompleted, isSuccessful, response]);

  const dataLength = Object.keys(data).length;

  return (
    <PageWrapper>
      {isSuccessful && dataLength > 0 && (
        <TableWithPagination data={data} setPage={setPage} />
      )}
      {isFailed && <h3>Ошибка загрузки</h3>}
    </PageWrapper>
  );
}
