import axios from "axios";
import { refreshAccessToken } from "shared/model/auth";

const urls = {
  //baseURL: `http://192.168.23.85:8080/`,
  //baseURL: `http://localhost:8000/`,
  baseURL: `http://ecp.rcud-rt.ru/`,
};

const baseApiInstanceWithoutToken = axios.create(urls);
const baseApiInstance = axios.create(urls);

baseApiInstance.interceptors.request.use(
  function (config) {
    const accessToken =
      JSON.parse(localStorage.getItem("app")).auth.accessToken || "";

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const baseApiInstanceWithRefresh = axios.create(urls);

baseApiInstanceWithRefresh.interceptors.request.use(
  function (config) {
    const accessToken =
      JSON.parse(localStorage.getItem("app")).auth.accessToken || "";

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

baseApiInstanceWithRefresh.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const {
      response: { status },
    } = error;
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      refreshAccessToken();
      return baseApiInstanceWithRefresh(originalRequest);
    }
    return Promise.reject(error);
  }
);

export {
  baseApiInstance,
  baseApiInstanceWithoutToken,
  baseApiInstanceWithRefresh,
};
