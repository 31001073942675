import { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import useApiGetRequestHook from "shared/api/getRequestHook";
import { ORGANIZATION_URL } from "shared/api/urls";

import { UPLOADING_STATUS } from "./constants";
import OrganizationSelect from "./molecules/OrganizationSelect";
import Icd10Modal from "./molecules/Icd10Modal";
import FilesUpload from "./molecules/FilesUpload";
import PageWrapper from "components/PageWrapper";

function UploadSlide() {
  const [organization, setOrganization] = useState("");
  const [organizations, setOrganizations] = useState([]);

  const [icd10, setIcd10] = useState({});

  const [status, setStatus] = useState(UPLOADING_STATUS.Initialization);

  const {
    isCompleted,
    isSuccessful,
    isFailed,
    response,
    error: organizationError,
  } = useApiGetRequestHook(ORGANIZATION_URL);

  useEffect(() => {
    if (isSuccessful) setOrganizations(response.data.results);
  }, [isCompleted, isSuccessful, response]);

  return (
    <PageWrapper>
      <Box sx={{ width: "70%", margin: "0 auto" }}>
        <OrganizationSelect
          organization={organization}
          organizations={organizations}
          setOrganization={setOrganization}
        />
        <Icd10Modal icd10={icd10} setIcd10={setIcd10} />
        <FilesUpload
          status={status}
          organization={organization}
          icd10={icd10}
          setStatus={setStatus}
        />
      </Box>
    </PageWrapper>
  );
}

export default UploadSlide;
