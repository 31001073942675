import rcud from "assets/img/rcud_logo.png";
import minpromtorg from "assets/img/minpromtorg.png";

const Footer = () => (
  <div className="aside-footer">
    <a href="https://rcud-rt.ru/">
      <img src={rcud} alt="" />
    </a>
    <a href="https://minpromtorg.gov.ru/">
      <img src={minpromtorg} alt="" />
    </a>
  </div>
);

export default Footer;
