import { useState, useEffect } from "react";
import { UPLOADING_STATUS } from "../constants";

import Box from "@mui/material/Box";
import FilesList from "./FilesList";
import FilesControls from "./FilesControls";
import { UploadButton } from "../atoms";

function Files({ status, organization, icd10, setStatus }) {
  const [files, setFiles] = useState([]);
  const [isLoadingStarted, setIsLoadingStarted] = useState(false);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);

  useEffect(() => {
    const isUploadButtonDisabled =
      status === (UPLOADING_STATUS.Loading || UPLOADING_STATUS.Success) ||
      !files.length ||
      !organization.length ||
      icd10.id === undefined;

    setIsUploadButtonDisabled(isUploadButtonDisabled);
  }, [status, files]);

  return (
    <Box sx={{ padding: 1 }}>
      <FilesControls
        files={files}
        status={status}
        setFiles={setFiles}
        setStatus={setStatus}
      />
      <FilesList
        files={files}
        status={status}
        organization={organization}
        icd10={icd10}
        isLoadingStarted={isLoadingStarted}
        setFiles={setFiles}
        setStatus={setStatus}
      />

      <UploadButton
        uploadHandler={() => setIsLoadingStarted(true)}
        isUploadButtonDisabled={isUploadButtonDisabled}
      />
    </Box>
  );
}

export default Files;
