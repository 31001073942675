import { UPLOADING_STATUS, DEFAULT_UPLOAD_QUEUE } from "../constants";
import { getDefaultUploadStates, getWaitingFiles } from "../lib";

const addFiles = (selectedFiles, files, setFiles, setStatus) => {
  const addedFiles = selectedFiles.filter((file) => {
    return files.findIndex((f) => f.name === file.name) === -1;
  });

  setFiles([...files, ...addedFiles]);
  setStatus(UPLOADING_STATUS.Initialization);
};

const selectFiles = (e, files, setFiles, setStatus) => {
  const target = e.target;

  addFiles([...target.files], files, setFiles, setStatus);
};

const removeFile = (index, files, setFiles, setStatus) => {
  const filteredFiles = files.filter((item, fileIndex) => fileIndex !== index);
  setFiles(filteredFiles);
  if (filteredFiles.length === 0) {
    setStatus(UPLOADING_STATUS.Initialization);
  }
};

const clearAllFiles = (setFiles, setStatus) => {
  setFiles([]);
  setStatus(UPLOADING_STATUS.Initialization);
};

const uploadFilesHandler = async (
  files,
  setUploadState,
  setUploadQueue,
  setStatus
) => {
  const defaultUploadStates = getDefaultUploadStates(files);
  setUploadState(defaultUploadStates);

  const waitingFiles = getWaitingFiles(files);
  setUploadQueue({
    ...DEFAULT_UPLOAD_QUEUE,
    waiting: waitingFiles,
  });

  setStatus(UPLOADING_STATUS.Loading);
};

export { addFiles, selectFiles, removeFile, clearAllFiles, uploadFilesHandler };
