import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const ProgressBar = ({ percentage }) => {
  return (
    <Box>
      <LinearProgress variant="determinate" value={percentage} />
      <span>{percentage}%</span>
    </Box>
  );
};

export default ProgressBar;
